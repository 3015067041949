import * as React from 'react';
import './Skills.css'
import { Container, Box, Typography } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
export default function Skills() {
    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Qualificações
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
            }}>
                <Box sx={{ borderWidth: '3px 1.5px 3px 3px', borderColor: '#a3a3a3', borderStyle: 'solid', width: { xs: 'full', md: '50%' }, padding: '40px 30px' }}>
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.5rem' }}>
                        <StorageOutlinedIcon fontSize='large' />
                        <Typography variant='h5'>
                            Desenvolvedor {' '}
                            <span className='background-text'>Backend</span>
                        </Typography>
                    </Box>
                    <Box className='box-text' >
                        <Box className='display-text'>
                            <Box sx={{ position: 'relative' }}>
                                <Typography className='text'>
                                    Experiência em desenvolvimento backend com tecnologias atuais e estruturação de projeto com <span className='background-text'>Node</span> e <span className='background-text'>NestJS</span>.
                                    Noção em banco de dados, com a utilização do <span className='background-text'>Prisma</span>.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ borderWidth: { xs: '0px 3px 3px 3px', md: '3px 3px 3px 0px' }, borderColor: '#a3a3a3', borderStyle: 'solid', width: { xs: 'full', md: '50%' }, padding: '40px 30px' }}>
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.5rem' }}>
                        <CodeIcon fontSize='large' />
                        <Typography variant='h5'>
                            Desenvolvedor {' '}
                            <span className='background-text'>Frontend</span>
                        </Typography>
                    </Box>
                    <Box className='box-text' >
                        <Box className='display-text'>
                            <Box className='teste' sx={{ position: 'relative' }}>
                                <Typography className='text'>
                                    Experiência em desenvolvimento frontend com tecnologias atuais e estruturação de projeto com <span className='background-text'>React</span> e <span className='background-text'>NextJS</span>.
                                    Noção em componentização, com a utilização de <span className='background-text'>UI's</span>.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ borderWidth: '0px 3px 3px 3px', borderColor: '#a3a3a3', borderStyle: 'solid', padding: '40px 30px' }}>
                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <BrushOutlinedIcon fontSize='large' />
                    <Typography variant='h5'>
                        Design {' '}
                        <span className='background-text'>Gráfico</span>
                    </Typography>
                </Box>
                <Box className='box-text' >
                    <Box className='display-text'>
                        <Box sx={{ position: 'relative' }}>
                            <Typography className='text'>
                                Experiência em design gráfico, formação técnica na instituição Etec Carlos de Campos no ano de 2022/2023, tenho experiência em design de interfaces de usuário <span className='background-text'>(UI)</span>  e experiência do usuário <span className='background-text'>(UX)</span>, especialmente em frontend, usando ferramentas como <span className='background-text'>Figma</span>. Busco equilibrar beleza estética e usabilidade prática em meus projetos, visando sempre a <span className='background-text'>melhor experiência</span> para o usuário.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}