import './App.css';
import About from './components/About/About';
import Experience from './components/Experience/Experience';
import Profile from './components/Profile/Profile';
import { Container } from '@mui/material';
import Skills from './components/Skills/Skills';

function App() {
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3rem', marginY: { xl: '6rem', xs: '2rem'  }  }} maxWidth="md">
      <Profile />
      <About />
      <Experience />
      <Skills />
    </Container>
  );
}

export default App;
