import * as React from 'react';
import { Typography, Container } from '@mui/material';
export default function About() {
    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Sobre mim
            </Typography>
            <Typography variant="body1" color={'gray'} >
                Olá! Sou o Rodrigo, tenho 18 anos e sou um entusiasta da programação desde muito jovem. Adoro resolver problemas e estou sempre buscando aprender coisas novas. Minha paixão é criar produtos que sejam intuitivos e dinâmicos, capazes de evoluir com base no feedback dos usuários. Gosto de ver minhas ideias se tornando realidade e estou sempre aberto a desafios que me ajudem a crescer.
            </Typography>
        </Container>
    )
}
