import * as React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
export default function Experience() {
    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Experiência
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: { xs: 'column', md: 'row' },
                        borderBottom: { xs: '1.5px solid DimGray', xl: '0px' },
                        paddingBottom: { xs: '2.5rem', xl: '1rem'}
                    }}
                >
                    <Box>
                        <Typography variant="subtitle" color={'DarkGray'} gutterBottom>
                            2023 - atualmente
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Link color={'#fff'} href="https://site.groundzerogz.com.br/" underline='none'>
                            <Typography variant="body1"  sx={{ display: 'flex', alignItems: 'baseline', gap: '0.2rem' }} >
                                Desenvolvedor Júnior Frontend - Groundzero  <NorthEastIcon sx={{ fontSize: 16 }} />
                            </Typography>
                        </Link>
                        <Typography variant="body2" color={'LightGray'} gutterBottom>
                            Trabalhando para o desenvolvimento de uma metodologia proprietária feita para impulsionar a perpetuidade empresarial de pequenas e médias empresas.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={'bold'} color={'DimGray'} borderBottom={'1px solid Lavender'} marginBottom={'0.8rem'} paddingBottom={'1rem'}>
                            Next • Tailwind • React • Flowbite
                        </Typography>
                        <Link color={'#fff'} href="https://solucoes.groundzerogz.com.br/" underline='none' >
                            <Typography variant="body1"  sx={{ display: 'flex', alignItems: 'baseline', gap: '0.2rem' }} >
                                Desenvolvedor Júnior Frontend - Groundzero Soluções  <NorthEastIcon sx={{ fontSize: 16 }} />
                            </Typography>
                        </Link>
                        <Typography variant="body2" color={'LightGray'} gutterBottom>
                            Uma solução exclusiva para evoluir o seu negócio, plataforma digital de fácil navegação para alcance de todos, desenvolvida para a sua empresa performar melhor.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={'bold'} color={'DimGray'} borderBottom={'1px solid Lavender'} marginBottom={'0.8rem'} paddingBottom={'1rem'}>
                            Next • Tailwind • React • Flowbite
                        </Typography>
                        <Link color={'#fff'} href="https://dashboard.groundzerogz.com.br/" underline='none'>
                            <Typography variant="body1"  sx={{ display: 'flex', alignItems: 'baseline', gap: '0.2rem' }} >
                                Desenvolvedor Júnior Fullstack - Groundzero Plataforma  <NorthEastIcon sx={{ fontSize: 16 }} />
                            </Typography>
                        </Link>
                        <Typography variant="body2" color={'LightGray'} gutterBottom>
                            Em breve...
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={'bold'} color={'DimGray'}>
                            Nest • Tailwind • Redux • Prisma • Aws Cognito
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box>
                        <Typography variant="subtitle" color={'DarkGray'} gutterBottom>
                            2023 - atualmente
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Link color={'#fff'} href="https://sistema.hunterjud.com/" underline='none'>
                            <Typography variant="body1"  sx={{ display: 'flex', alignItems: 'baseline', gap: '0.2rem' }} >
                                Desenvolvedor Júnior Fullstack - Hunterjud  <NorthEastIcon sx={{ fontSize: 16 }} />
                            </Typography>
                        </Link>
                        <Typography variant="body2" color={'LightGray'} gutterBottom>
                            Consultorias desenvolvidas para melhor acesso do usuário, com estátisticas e dashboard's feitos para melhor experiências com suas buscas.
                        </Typography>
                        <Typography variant="subtitle2" fontWeight={'bold'} color={'DimGray'}>
                            Express • React • Mercado Pago • Docker
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
