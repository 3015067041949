import * as React from 'react';
import { Avatar, Typography, Container, Link, Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export default function Profile() {
    return (
        <Container sx={{ display: 'flex', alignItems: 'end', gap: '1.5rem' }}>
            <Box>
                <Avatar alt='Rodrigo Gomes Avatar' src='/profile-image.png' sx={{ width: 126, height: 126 }} />
            </Box>
            <Box>
                <Typography variant="h5" >
                    Rodrigo Gomes
                </Typography>
                <Typography variant="subtitle2" color={'gray'} fontWeight={'bold'} gutterBottom>
                    Desenvolvedor Júnior front-end
                </Typography>
                <Link href="https://github.com/RodrigoGomesDev" rel="github link" target="_blank" color={'#E2E8F0'}>
                    <GitHubIcon />
                </Link>
                <Link href="https://www.linkedin.com/in/rodrigogomesdev/" rel="linkedin link" target="_blank"  sx={{ marginX: '0.5rem' }} color={'#E2E8F0'}>
                    <LinkedInIcon />
                </Link>
            </Box>
        </Container>
    )
}
